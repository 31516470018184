<template>
  <div class="mt-3">
    <div class="nk-ecwg7-ck">
      <canvas class="ecommerce-doughnut-s1" id="dogn"></canvas>
    </div>
    <ul class="nk-ecwg7-legends d-block">
      <li v-for="(label,index) in labels" :key="index" class="pb-0">
        <div class="title">
          <nk-dot size="lg" :color="colors[index]"></nk-dot>
          <span>{{ $t(label) }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { watch, toRefs } from "vue";
import { NkDot } from "@core/components"
import { Chart, ArcElement, Tooltip, DoughnutController, Filler } from "chart.js"
Chart.register(DoughnutController, ArcElement, Tooltip, Filler);
import useCommonFunc from "@core/comp-functions/common"

export default {
  props: {
    labels: {
      required: true,
      type: Array
    },
    data: {
      required: true,
      type: Array,
    },
    colors: {
      required: true,
      type: Array
    },
    dataUnit: {
      type: String
    },
    options: Object,
  },
  components: {
    NkDot
  },
  setup(props){
    const {updateObject} = useCommonFunc()
    const { data } = toRefs(props)
    watch(data, () => {
      let chartItem = document.getElementById('dogn').getContext('2d')
      new Chart(chartItem, {
        type: 'doughnut',
        options: updateObject({
          plugins:{
            legend:{
              display: false,
            },
          },
        }, props.options || {}, true, true),
        data: {
          labels: props.labels,
          dataUnit: props.dataUnit,
          datasets: [
            {
              backgroundColor: props.colors,
              data: props.data
            }
          ]
        }
      })
    })
  },
  mounted() {
    this.$nextTick(()=>{

    })
  }
}
</script>

