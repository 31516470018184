<template>
  <page-template>
    <main-block>
      <block-head :title="$t('greetings', {name: userData.first_name})" :description="$t('welcome_to_crm', {app_name: appName})"></block-head>
      <block-content>
        <row class="g-gs">
          <column :xxl="8">
            <card>
              <template v-slot:grouped>
                <card-inner>
                  <card-header :title="$t('TO DO')"></card-header>
                </card-inner>
                <card-inner v-if="!dashboard_data.todo.unassigned_appointments.length && !dashboard_data.todo.followup_suggestions.length">
                  <p class="text-muted fw-500">{{ $t('You will see a todo list here.') }}</p>
                </card-inner>
                <div v-else>
                  <template v-if="dashboard_data.todo.unassigned_appointments.length">
                    <card-inner v-for="lead in dashboard_data.todo.unassigned_appointments" :key="'uol'+lead.AL_id" class="card-inner-sm">
                      <h6>{{ $t('Appointment')}} #{{lead.lead_number}}</h6>
                      <p class="text-muted">{{ $t('Assign this appointment to advisor.')}}</p>
                      <nk-button type="primary" outline round v-on:click="$router.push({name: 'apAgentLeadDetails', params: {id: lead.AL_id}})">{{ $t('View Details')}}</nk-button>
                    </card-inner>
                  </template>
                  <template v-if="dashboard_data.todo.followup_suggestions.length">
                    <card-inner v-for="lead in dashboard_data.todo.followup_suggestions" :key="'fs'+lead.id">
                      <template v-if="lead.next_followup.future_date || lead.next_followup.type === 'Appointment'">
                        <template v-if="lead.next_followup.future_date">
                          <h6 style="color: #00c58c;">{{ $t("lead_followup_wizard.upcoming_followup_type_reminder", {followup_type: $t(lead.next_followup.type).toLowerCase()})}}</h6>
                          <p class="text-muted"> {{$t(lead.next_followup.type)+' '+$t('at')+' '+lead.next_followup.future_date}}</p>
                        </template>
                        <template v-else>
                          <h6 style="color: #00c58c;">{{ $t("lead_followup_wizard.how_was_your_appointment")}} {{' #'+lead.lead_number}}</h6>
                          <p class="text-muted">
                            {{ $t('lead_followup_wizard.you_had_a_followup_how_did_it_go', {date: lead.last_followup.date, time: lead.last_followup.time, followup_type: $t('Appointment').toLowerCase()})}}
                          </p>
                        </template>
                      </template>
                      <template v-else>
                        <h6 style="color: #00c58c;">{{ $t("It's time to reach out to your lead!")+' #'+lead.lead_number}}</h6>
                        <p v-if="lead.last_followup.time && lead.next_followup.date" class="text-muted" >
                          {{ $t('lead_followup_wizard.you_had_a_followup_how_did_it_go', {date: lead.last_followup.date, time: lead.last_followup.time, followup_type: $t('Follow up').toLowerCase()})}}
                        </p>
                        <p v-else-if="lead.last_followup.time" class="text-muted">
                          {{$t('lead_followup_wizard.last_followup_unreachable_lead_try_again_if_u_want', {date: lead.last_followup.date, time: lead.last_followup.time})}}
                        </p>
                        <p v-else class="text-muted">{{ $t('lead_followup_modal.followup_asap_is_crucial_msg')}}<br/>{{ $t('lead_followup_modal.time_since_received_lead', {time: lead.received_time}) }}</p>
                      </template>
                      <nk-button type="primary" outline round v-on:click="$router.push({name: 'apAgentLeadDetails', params: {id: lead.id}})">{{ $t('View Details')}}</nk-button>
                    </card-inner>
                  </template>
                </div>
              </template>
            </card>
          </column>

          <column :xxl="4" :md="6">
            <card no-padding class="pb-4">
              <card-header :title="$t('Leads by status')" class="p-2"></card-header>
              <card-inner v-if="!dashboard_data.pie_chart.values.length">
                <p class="text-muted fw-500">{{ $t('pages.ap_agent_home.leads_by_status_chart_placeholder')}}</p>
              </card-inner>
              <chartjs-doughnut v-show="dashboard_data.pie_chart.values.length"
                                :data="dashboard_data.pie_chart.values"
                                :labels="dashboard_data.pie_chart.labels"
                                :colors="dashboard_data.pie_chart.colors"
                                data-unit="Leads">

              </chartjs-doughnut>
            </card>
          </column>
        </row>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {onIonViewWillEnter} from '@ionic/vue';
import {computed, defineComponent, reactive, /*nextTick*/} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import { Row, Column } from '@core/layouts';
import { Card, CardHeader } from '@core/components/cards'
import ChartjsDoughnut from "@core/components/charts/ChartjsDoughnut";
import axios from "@/libs/axios";

import "bootstrap"
import { useStore } from "vuex"
import { useRouter} from "vue-router"
import CardInner from "@core/components/cards/components/CardInner";
import BlockHead from "@core/layouts/main-block/components/BlockHead";
import useGeneralFunc from '@core/comp-functions/common'
import NkButton from "@core/components/button/NkButton";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    NkButton,
    BlockHead,
    CardInner,
    ChartjsDoughnut,
    CardHeader,
    Card,
    Row,
    Column,
    BlockContent,
    MainBlock,
  },
  setup() {

    const router = useRouter()
    const store  = useStore()
    const appName = process.env.VUE_APP_NAME
    const{updateObject} = useGeneralFunc()

    let dashboard_data = reactive({
      counts: {
        this_week: {
          total_leads: 0,
          avail_leads: 0,
        },
      },
      leads_statuses: [],
      todo: {
        followup_suggestions: [],
        unassigned_appointments: [],
      },
      pie_chart: {
        labels: [],
        values: [],
        colors: [],
      },
    })
    const getDashboardData = ()=>{
      axios.get('/api/ap_agent/dashboard')
          .then( (resp) => {
            updateObject(dashboard_data, resp.data)
          })
          .catch( err => {
            console.log('Dashboard ajax error: ', err)
          })
    }

    onIonViewWillEnter(()=>{
      getDashboardData()
    })

    const userData = computed(()=>{
      return store.state.user_data
    })

    return {
      appName,
      dashboard_data,
      router,
      store,
      userData,
    }
  }
});
</script>
